"use strict";

import {add} from '@elements/scroll-animations';
import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('.js-parallax', function (baseElement) {
        let parallaxElements = [];
        parallaxElements.push(baseElement);

        // animation should be visible on full page
        let continueParallax = baseElement.getAttribute('data-parallax-continue') || false;
        // if animation should only be on bottom of page
        let fadeInParallax = baseElement.getAttribute('data-parallax-fade-in') || false;
        let heroParallax = baseElement.getAttribute('data-parallax-hero') || false;

        let parallaxXValue = baseElement.getAttribute('data-parallax-x-value') || 0;
        let parallaxYValue = baseElement.getAttribute('data-parallax-y-value') || 0;

        add(
            parallaxElements,
            calculateAnimationProgress({continueParallax, heroParallax, fadeInParallax}),
            setAnimationProgress({parallaxXValue, parallaxYValue})
        );
    });
}

const isMobile = () => matchMedia('(max-width: 767px)').matches;

// gets element -> returns number between 0 and 1
function calculateAnimationProgress({continueParallax, heroParallax, fadeInParallax}) {
    return function(element) {
        let start = window.innerHeight - 100;
        let end = 300;

        if(continueParallax) {
            end = element.offsetHeight * (-1);
        } else if (fadeInParallax) {
            end = window.innerHeight / 1.4;
        }  else if(heroParallax) {
            // animation for hero
            end = 100;
        }


        let progress;
        if(heroParallax) {
            progress =  Math.max(
                Math.min(
                    (element.getBoundingClientRect().top - end) / (end - start),
                    1
                ),
                0
            );
            
        } else {
            progress =  Math.max(
                Math.min(
                    (element.getBoundingClientRect().top - end) / (start - end),
                    1
                ),
                0
            );
        }

        return progress;
    };
}

// gets element & progress (number between 0 - 1) -> sets style of element
function setAnimationProgress({parallaxXValue, parallaxYValue}) {
    return function(element, progress) {

        let parallaxMultiplicator = isMobile() ? 0.3 : 1;
        let parallaxXProgress,
            parallaxYProgress;

        parallaxXProgress = (progress * (parallaxXValue * parallaxMultiplicator)) + "px";
        parallaxYProgress = (progress * (parallaxYValue * parallaxMultiplicator)) + "px";

        element.style.transform = "translate3d(" + parallaxXProgress + ", "+ parallaxYProgress +", 0)";
    }
}
