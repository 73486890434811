"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {removeClass, on, addClass} from '@elements/dom-utils';
import {EVENTS} from '@elements/affix';

const defaultOptions = {};

const defaultSelectors = {
    base: '.js-waypoint-nav',
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createExperience(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createExperience(baseElement, options, selectors) {

    function setAffixHandler(evt) {
        addClass('waypoint-nav--is-active', baseElement);
    }

    function unsetAffixHandler(evt) {
        if(baseElement.getBoundingClientRect().top >0) {
            removeClass('waypoint-nav--is-active', baseElement);
        }
    }

    on(EVENTS.AFFIX, setAffixHandler, baseElement);
    on(EVENTS.DETACH, unsetAffixHandler, baseElement);
}
