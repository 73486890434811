import './scripts/common';
import {onFind} from "@elements/init-modules-in-scope";

let _config = window['_config'] || {};


let app = {
    debug: false,
};
window.app = app || {};

import * as affix from '@elements/affix';
affix.init();

import * as parallax from './scripts/parallax';
parallax.init();

import * as fadeIn from './scripts/fade-in';
fadeIn.init();

import * as smoothScroll from '@elements/smooth-scroll';
smoothScroll.init();

import * as waypointNav from './scripts/waypoint-nav';
waypointNav.init();

import ScrollSpy from 'bootstrap/js/dist/scrollspy';
onFind('[data-bs-spy="scroll"]',function (element) {
    console.log("found"); 
    new ScrollSpy(document.body, {
        target: '#waypoint-navbar',
        offset: 350,
        method: 'auto'
    })
});


